import { Box, Container, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    marginTop: '2rem',
  },
}));

function Falcon() {
  const classes = useStyles();
  // const [searchParams] = useSearchParams();

  return (
    <Box sx={{ pt: 8, pb: 6 }}>
      <Container>
        <Typography variant='h3' component='h3'>
          Falcon
        </Typography>
        <div className={classes.section}>
          <Typography variant='h4' component='h4'>
            Update Instagram
          </Typography>
          <Typography gutterBottom>Update the token</Typography>
          <Link
            href={`https://www.instagram.com/oauth/authorize?client_id=660950192090979&redirect_uri=${window.location.href}/&scope=user_profile,user_media&response_type=code`}
          >
            Connect Insta
          </Link>
        </div>
      </Container>
    </Box>
  );
}

export default Falcon;
