import CssBaseline from '@mui/material/CssBaseline';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/Home';
import Learning from './pages/Learning';
import Profile from './pages/Profile';
import Falcon from './pages/Falcon';
import { useAuth } from 'providers/auth';
import { makeStyles } from '@mui/styles';
import Login from 'pages/Login';

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function Root() {
  const { authIsReady, user } = useAuth();
  const classes = useStyles();

  if (!authIsReady) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <CssBaseline />
      <Router>
        <Header />
        <main>
          <Routes>
            <Route
              path='/login'
              element={user ? <Navigate to='/profile' /> : <Login />}
            />

            <Route
              path='/learning'
              element={!user ? <Navigate to='/login' /> : <Learning />}
            />

            <Route
              path='/profile'
              element={!user ? <Navigate to='/login' /> : <Profile />}
            />

            <Route
              path='/falcon'
              element={!user ? <Navigate to='/login' /> : <Falcon />}
            />

            <Route index element={<Home />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </main>
      </Router>
      <Footer />
    </>
  );
}

export default Root;
