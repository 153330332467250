import React, { createContext, useContext, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { Firestore, getFirestore } from 'firebase/firestore';
import env from '../env';

export interface IFirebaseContext {
  store: Firestore;
  auth: Auth;
  analytics: Analytics;
}

export const FirebaseContext = createContext<IFirebaseContext>({} as any);

export const useFirebase = () => useContext(FirebaseContext);

export function FirebaseProvider({ children }: React.PropsWithChildren<{}>) {
  const { store, auth, analytics } = useMemo(() => {
    const app = initializeApp(env.firebase);
    const store = getFirestore(app);
    const auth = getAuth(app);
    const analytics = getAnalytics(app);

    return {
      app,
      store,
      auth,
      analytics,
    };
  }, []);

  return (
    <FirebaseContext.Provider value={{ store, auth, analytics }}>
      {children}
    </FirebaseContext.Provider>
  );
}
