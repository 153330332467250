import { Container } from '@mui/material';
import YoutubeEmbed from 'components/youtube/YoutubeEmbed';

interface Video {
  teacher: string;
  video: string;
}

const VIDEOS: Video[] = [
  { teacher: 'Saturno Movement', video: 'oBcWjpFG5yY' },
  { teacher: 'Natalie Reckert', video: '4d5iAtSLI1c' },
  { teacher: 'Tom Merrick', video: 'oJy6MJ-JLbw' },
  { teacher: 'Andrew Alinda', video: 'I32a_lZRVaM' },
];

function Learning() {
  return (
    <div>
      <Container maxWidth='md'>
        <h2>Follow along training</h2>

        <div>
          {VIDEOS.map(({ teacher, video }) => (
            <div key={video}>
              <h3>{teacher}</h3>
              <YoutubeEmbed embedId={video} />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default Learning;
