import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

function About() {
  return (
    <Container sx={{ py: 8 }} maxWidth='md' id='about'>
      <Typography gutterBottom variant='h2' component='h2' align='center'>
        About
      </Typography>
      <Typography gutterBottom align='center'>
        Our mission is to connect coaches with starters all over the world to
        help on the first stages of handstanding. It is built by a group of
        passionate people that struggled in their early days and want to make
        things easier for others. A long journey that is always better with
        great companionship.
      </Typography>
    </Container>
  );
}

export default About;
