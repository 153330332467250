import { makeStyles } from '@mui/styles';

interface Props {
  embedId: string;
}

const useStyles = makeStyles(() => ({
  videoResponsive: {
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0,
  },
  iframe: {
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
}));

function YoutubeEmbed({ embedId }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.videoResponsive}>
      <iframe
        className={classes.iframe}
        width='853'
        height='480'
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded youtube'
      />
    </div>
  );
}

export default YoutubeEmbed;
