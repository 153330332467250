import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

import useFirebaseFetchDoc from 'hooks/useFirebaseFetchDoc';
import { useAuth } from 'providers/auth';
import { User } from 'types/user.types';

interface UserData {
  email: string;
  name: string;
}

const useStyles = makeStyles(() => ({
  loaderContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  section: {
    marginTop: '2rem',
  },
}));

function Profile() {
  const classes = useStyles();
  const { deleteUser, user } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = (user: User) => {
    handleClose();
    deleteUser(user);
  };

  const userInfo = useFirebaseFetchDoc<UserData>(`users/${user?.uid}`);

  return (
    <Box sx={{ pt: 8, pb: 6 }}>
      <Container>
        <Typography variant='h3' component='h3'>
          Profile
        </Typography>
        {!userInfo && <CircularProgress />}
        {userInfo && <UserDetails userData={userInfo} />}
        <div className={classes.section}>
          <Typography variant='h4' component='h4'>
            Delete Account
          </Typography>
          <Typography gutterBottom>
            If you want to remove your account you can do it from here. This
            will delete all your data and it can't be recovered.
          </Typography>
          {user && (
            <>
              <Button variant='outlined' color='error' onClick={handleOpen}>
                Delete Account
              </Button>
              <Dialog
                hideBackdrop
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  Confirm User Deletion
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Are you sure you want to delete your user information?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color='primary' onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    color='error'
                    onClick={() => handleDelete(user)}
                    autoFocus
                  >
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </div>
      </Container>
    </Box>
  );
}

interface Props {
  userData: UserData;
}

function UserDetails({ userData }: Props) {
  const { email, name } = userData;

  return (
    <>
      <Typography>Name: {name}</Typography>
      <Typography>Email: {email}</Typography>
    </>
  );
}

export default Profile;
