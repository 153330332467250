import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth } from '../../providers/auth';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {
    margin: 'auto',
    maxWidth: theme.breakpoints.values.lg,
    width: '100%',
  },
}));

function Header() {
  const classes = useStyles();
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  return (
    <AppBar position='sticky'>
      <Toolbar className={classes.toolBar}>
        <Typography
          variant='h6'
          color='inherit'
          noWrap
          sx={{ flexGrow: 1 }}
          onClick={() => navigate('/')}
        >
          Handstand Club
        </Typography>
        {/* {!user && (
          <Button color='inherit' onClick={() => navigate('/login')}>
            Login
          </Button>
        )} */}
        {user && (
          <>
            <Button color='inherit' onClick={logout}>
              Logout
            </Button>
            <Avatar
              alt={user.displayName || ''}
              src={user.photoURL || ''}
            ></Avatar>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
