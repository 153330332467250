import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Card } from '@mui/material';
import YoutubeEmbed from 'components/youtube/YoutubeEmbed';

interface Video {
  teacher: string;
  video: string;
  description?: string;
}

const VIDEOS: Video[] = [
  {
    teacher: 'Saturno Movement',
    video: 'oBcWjpFG5yY',
    description: 'Daily Handstand Routine for Beginners (Follow Along)',
  },
  {
    teacher: 'Natalie Reckert',
    video: '4d5iAtSLI1c',
    description: 'Daily handstand practice routine (follow along)',
  },
  {
    teacher: 'Tom Merrick',
    video: 'oJy6MJ-JLbw',
    description: '20 Minute Beginner Handstand Routine (FOLLOW ALONG)',
  },
  {
    teacher: 'Andrew Alinda',
    video: 'I32a_lZRVaM',
    description: 'How To Frog To Handstand | Progressions & Training Guide',
  },
];

function Stars() {
  return (
    <Container sx={{ py: 8 }} maxWidth='md' id='stars'>
      <Typography gutterBottom variant='h2' component='h2' align='center'>
        Stars
      </Typography>
      <Typography gutterBottom align='center'>
        People that are turning the world upside down. These are some online
        resources that we have found great for beginners of the art of hand
        balance.
      </Typography>

      <Grid sx={{ my: 4 }} container spacing={4}>
        {VIDEOS.map(({ teacher, video, description }) => (
          <Grid item key={video} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardMedia component='div'>
                <YoutubeEmbed embedId={video} />
              </CardMedia>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant='h5' component='h2'>
                  {teacher}
                </Typography>
                {description && <Typography>{description}</Typography>}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Stars;
