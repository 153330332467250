import TagManager, { TagManagerArgs } from 'react-gtm-module';
import env from '../env';
import { IEvent } from '../types/events';
import Logger from './Logger';

const logger = Logger.create('EventManager');

class EventManager {
  initialize() {
    const { gtmId, auth, env: preview } = env.gtm;
    const args: TagManagerArgs = {
      gtmId,
      auth,
      preview,
    };
    logger.log('initialize()', args);
    TagManager.initialize(args);
  }

  track(event: IEvent) {
    logger.log('track(): ', event.event, event);

    try {
      TagManager.dataLayer({ dataLayer: event });
    } catch (e) {
      if (e instanceof Error) {
        logger.log('track(): error', e.message);
      } else {
        logger.log('track(): unexpected error', e);
      }
    }
  }

  set(dataLayer: Object) {
    try {
      TagManager.dataLayer({ dataLayer: dataLayer });
    } catch (e) {
      if (e instanceof Error) {
        logger.log('set(): error', e.message);
      } else {
        logger.log('set(): unexpected error', e);
      }
    }
  }
}

export default new EventManager();
