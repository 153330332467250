import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CardMedia from '@mui/material/CardMedia';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardHeader,
} from '@mui/material';
import { green } from '@mui/material/colors';
import axios from 'axios';
import useFirebaseFetchDoc from 'hooks/useFirebaseFetchDoc';
import Logger from 'utils/Logger';

const INSTA_URL = 'https://graph.instagram.com/me/media';

interface InstaPost {
  id: string;
  media_type: string;
  media_url: string;
  timestamp: string;
  permalink: string;
}

interface InstaAuth {
  token: string;
}

const logger = Logger.create('Media');

function Media() {
  const [posts, setPosts] = useState<InstaPost[]>([]);
  const instaAuth = useFirebaseFetchDoc<InstaAuth>('falcon/insta');

  async function getPosts(instaAuth: InstaAuth | null) {
    if (instaAuth?.token) {
      try {
        const response = await axios.get(
          `${INSTA_URL}?fields=id,media_type,media_url,timestamp,permalink&limit=9&access_token=${instaAuth?.token}`
        );
        logger.log(response.data);
        setPosts(response.data.data);
      } catch (err) {
        logger.error(err);
      }
    }
  }

  useEffect(() => {
    getPosts(instaAuth);
  }, [instaAuth]);

  return (
    <Container sx={{ py: 8 }} maxWidth='md' id='stars'>
      <Typography gutterBottom variant='h2' component='h2' align='center'>
        Media
      </Typography>
      <Typography gutterBottom align='center'>
        Engage with us in instagram. Do you want to get featured showing your
        progress? We are always happy to share nice handstands in order to
        inspire other people.
      </Typography>

      <Grid sx={{ my: 4 }} container spacing={4}>
        {posts.length > 0 &&
          posts.map(({ id, media_url, timestamp, permalink }) => {
            const dateOptions: Intl.DateTimeFormatOptions = {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            };
            const date = new Date(timestamp).toLocaleDateString(
              'en-EN',
              dateOptions
            );

            return (
              <Grid item key={id} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardActionArea
                    href={permalink}
                    target='_blank'
                    rel='nofollow noreferrer'
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{ bgcolor: green[500] }}
                          aria-label='recipe'
                        >
                          HC
                        </Avatar>
                      }
                      title='handstand_club'
                      subheader={date}
                    />
                    <CardMedia
                      component='img'
                      height='350'
                      image={media_url}
                      alt='instagram-handstand-club'
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
      </Grid>

      <Grid sx={{ flexGrow: 1 }} container justifyContent='center'>
        <Button
          color='primary'
          variant='contained'
          href='https://www.instagram.com/handstand_club/'
          target='_blank'
          rel='nofollow noreferrer'
        >
          See More
        </Button>
      </Grid>
    </Container>
  );
}

export default Media;
