const env = {
  gtm: {
    gtmId: process.env.REACT_APP_GTM_ID || '',
    auth: process.env.REACT_APP_GTM_AUTH || '',
    env: process.env.REACT_APP_GTM_ENV || '',
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  insta: process.env.REACT_APP_INSTA,
};

export default env;
