import About from 'components/home/about/About';
import Intro from 'components/home/intro/Intro';
import Stars from 'components/home/stars/Stars';
import Media from 'components/home/media/Media';

function Home() {
  return (
    <>
      <Intro />
      <About />
      <Stars />
      <Media />
    </>
  );
}

export default Home;
