import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirebase } from 'providers/firebase';

import Logger from 'utils/Logger';

const logger = Logger.create('useFirebaseFetchDoc');

export default function useFirebaseFetchDoc<T>(path: string): T | null {
  const [data, setData] = useState<T | null>(null);
  const { store } = useFirebase();

  useEffect(() => {
    const unsub = onSnapshot(doc(store, path), (doc) => {
      logger.info(`Succesfully fetched `, doc.data());
      setData(doc.data() as T);
    });

    return () => unsub();
  }, [path, store]);

  return data;
}
