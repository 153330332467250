import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  sendEmailVerification,
  signInWithPopup,
  signOut,
  deleteUser as firebaseDeleteAuthUser,
  reauthenticateWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';

import { User } from 'types/user.types';
import Logger from '../utils/Logger';
import { useFirebase } from './firebase';
import EventManager from '../utils/EventManager';

const provider = new GoogleAuthProvider();

const logger = Logger.create('auth');

export interface IAuthContext {
  loading: boolean;
  isAuthenticated?: boolean;
  user: User | null;
  authIsReady: boolean;
  login(): void;
  logout(): void;
  deleteUser(currentUser: User): void;
}

export const AuthContext = createContext<IAuthContext>({
  loading: false,
  isAuthenticated: false,
  user: null,
  authIsReady: false,
  logout: () => {},
  login: () => {},
  deleteUser: () => {},
});

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: React.PropsWithChildren<{}>) {
  const [loading, setLoading] = useState(false);
  const [authIsReady, setAuthIsReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const { auth, store } = useFirebase();

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setUser(user);
      setAuthIsReady(true);
      unsub();
    });
  }, [auth]);

  async function login() {
    logger.log('login()');
    setLoading(true);

    try {
      const response = await signInWithPopup(auth, provider);

      const user = response.user;
      logger.log('user: ', user);
      const userRef = doc(store, 'users', user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userInfo: User = userSnap.data() as User;

        setUser(userInfo);
      } else {
        await setDoc(doc(store, 'users', user.uid), {
          uid: user.uid,
          name: user.displayName,
          authProvider: 'google',
          email: user.email,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          isAnonymous: user.isAnonymous,
        });

        verifyUser(user);

        setUser(user);
      }

      if (user?.uid) {
        EventManager.set({ user_id: user.uid });
      }
      setIsAuthenticated(true);
      setLoading(false);
    } catch (err) {
      logger.error('error ', err);
      setLoading(false);
    }
  }

  async function logout() {
    logger.log('logout()');
    setLoading(true);

    try {
      signOut(auth);

      logger.log('logged out succesfully');
      setUser(null);
      setLoading(false);
    } catch (err) {
      logger.error('logout error: ', err);
      setLoading(false);
    }
  }

  async function verifyUser(currentUser: User) {
    try {
      await sendEmailVerification(currentUser);

      logger.log('Verification sent');
    } catch (err) {
      logger.error('error sending verification email', err);
    }
  }

  async function deleteAuthUser(currentUser: User) {
    try {
      // Delete user auth
      await firebaseDeleteAuthUser(currentUser);
      logger.log('User auth deleted');
      setUser(null);
    } catch (err) {
      logger.error('error deleting user auth', err);
    }
  }

  async function deleteUserData(uid: string) {
    try {
      await deleteDoc(doc(store, 'users', uid));
      logger.log('User data deleted');
    } catch (err) {
      logger.error('error deleting user data', err);
    }
  }

  async function deleteUser(currentUser: User) {
    try {
      const cred = await reauthenticateWithPopup(currentUser, provider);
      if (!cred) {
        logger.log('User NOT reauthenticated');
        return;
      }

      logger.log('User reauthenticated');
      await deleteUserData(currentUser.uid);
      await deleteAuthUser(currentUser);
    } catch (err) {
      logger.error('error reauthenticating user', err);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        isAuthenticated,
        user,
        login,
        logout,
        authIsReady,
        deleteUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
