import React from 'react';
import Logger from '../../utils/Logger';

const logger = Logger.create('EventManager');

interface State {
  error?: Error;
  errorInfo?: React.ErrorInfo;
}

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<any>,
  State
> {
  state: State = {
    error: undefined,
    errorInfo: undefined,
  };

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logger.error('ERROR', error, errorInfo);
    this.setState({
      error,
      errorInfo,
    });
  }

  render(): JSX.Element {
    const { children } = this.props;
    const { errorInfo, error } = this.state;

    if (!error || !errorInfo) {
      return <>{children}</>;
    }

    // @TODO: improve error
    return <div>There has been an error</div>;
  }
}

export default ErrorBoundary;
