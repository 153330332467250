import { createTheme, ThemeProvider } from '@mui/material/styles';

import { FirebaseProvider } from './providers/firebase';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import Root from './Root';
import AuthProvider from './providers/auth';

const theme = createTheme({
  palette: {
    primary: {
      main: '#499F68',
    },
    secondary: {
      main: '#895737',
    },
  },
});

export default function App() {
  return (
    <ErrorBoundary>
      <FirebaseProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Root />
          </ThemeProvider>
        </AuthProvider>
      </FirebaseProvider>
    </ErrorBoundary>
  );
}
