import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';
import EventManager from '../../../utils/EventManager';

const useStyles = makeStyles((theme: Theme) => ({
  containerImage: {
    backgroundImage: `url(
      'https://res.cloudinary.com/dcw7kvha3/image/upload/v1648927431/handstand/handstand-beach.jpg'
    )`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100%',
  },
  overlay: {
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
    height: '100%',
  },
}));

function Intro() {
  const classes = useStyles();

  function onClick() {
    EventManager.track({
      event: 'ButtonPressed',
      buttonId: 'more-info',
    });

    const anchor = document.querySelector('#about');
    if (!anchor) {
      return;
    }
    anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  return (
    <div className={classes.containerImage}>
      <div className={classes.overlay}>
        <Box sx={{ pt: 8, pb: 6 }}>
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='text.primary'
              gutterBottom
            >
              Passion for handstands
            </Typography>
            <Typography
              variant='h5'
              align='center'
              color='text.secondary'
              paragraph
            >
              Enjoy the world from a different perspective.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction='row'
              spacing={2}
              justifyContent='center'
            >
              <Button color='primary' variant='contained' onClick={onClick}>
                More
              </Button>
            </Stack>
          </Container>
        </Box>
      </div>
    </div>
  );
}

export default Intro;
